function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { css } from '@emotion/react';
export var bannerStyle = true ? {
  name: "107cmgv",
  styles: "width:100%;&:hover{cursor:pointer;}"
} : {
  name: "wij3q9-bannerStyle",
  styles: "width:100%;&:hover{cursor:pointer;};label:bannerStyle;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlLWdsb2JhbC9jb21wb25lbnRzL2hvbWVwYWdlLWJhbm5lci13aWRnZXQvaG9tZXBhZ2UtYmFubmVyLXdpZGdldC5tb2R1bGUuc3R5bGUudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUUyQiIsImZpbGUiOiIvdXNyL3NyYy9hcHAvYXBwcy9tYXJrZXRwbGFjZS1nbG9iYWwvY29tcG9uZW50cy9ob21lcGFnZS1iYW5uZXItd2lkZ2V0L2hvbWVwYWdlLWJhbm5lci13aWRnZXQubW9kdWxlLnN0eWxlLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IGNzcyB9IGZyb20gJ0BlbW90aW9uL3JlYWN0JztcblxuZXhwb3J0IGNvbnN0IGJhbm5lclN0eWxlID0gY3NzKHtcbiAgd2lkdGg6ICcxMDAlJyxcbiAgJyY6aG92ZXInOiB7XG4gICAgY3Vyc29yOiAncG9pbnRlcicsXG4gIH0sXG59KTtcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
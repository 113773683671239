import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import { DOLLAR_CURRENCY_ID, LaymanHomePageSectionTypes, SourceKey, SourceNameKey } from '@vc-workspace/utils-constants';
import { amountFormatter } from '@vc-workspace/utils-formatters';
import { getDisplayImageObj } from '@vc-workspace/utils-helpers';
import { Typography } from '@vc-workspace/utils-typography';
import Link from 'next/link';
import { useContext } from 'react';
import { AppConfigContext } from '../global-context-wrapper/global-context-wrapper';
import { collectionListContainer, collectionItemContainer, collectionPatternImage, collectionItemPrimarySIAContainer, collectionItemSecondarySIAContainer, collectionItemSIAPrice, collectionItemContentContainer } from './collection-group.module.style';
/* eslint-disable-next-line */

var CollectionConfig = [{
  patternImage: '/_mp-images/collection_pattern1.png',
  textColor: 'saddle_brown'
}, {
  patternImage: '/_mp-images/collection_pattern2.png',
  textColor: 'indigo'
}, {
  patternImage: '/_mp-images/collection_pattern3.png',
  textColor: 'cedar_wood_finish'
}];
export function CollectionGroup(props) {
  var _useContext = useContext(AppConfigContext),
      isLoggedIn = _useContext.isLoggedIn;

  return _jsxs("div", {
    className: props.context.isMobileView ? 'pt-8' : 'pt-16',
    children: [_jsx(Typography, {
      type: props.context.isMobileView ? 'SubHeading1' : 'Headline_H2',
      color: "prussian_blue",
      className: "page-cushion-container",
      extra_bold: true,
      children: props.data.title
    }), _jsx("div", {
      css: collectionListContainer,
      className: props.context.isMobileView ? 'page-cushion-container--left' : 'page-cushion-container',
      children: props.data.data.collections.slice(0, 3).map(function (collection, index) {
        var _siaDisplayImages$, _siaDisplayImages$2, _collection$siaDetail, _siaDisplayImages$3, _siaDisplayImages$4, _collection$siaDetail2, _siaDisplayImages$5, _siaDisplayImages$6, _collection$siaDetail3;

        if (!collection.siaDetailsLite) {
          collection.siaDetailsLite = [];
        }

        var siaDisplayImages = collection.siaDetailsLite.map(function (item) {
          return getDisplayImageObj(item.itemDetail.images[0], '2');
        }) || [];
        return _jsx(Link, {
          href: "/collection/".concat(collection.slug, "/").concat(collection.usId, "?").concat(SourceKey, "=").concat(LaymanHomePageSectionTypes.SIA_COLLECTION_GROUP, "&").concat(SourceNameKey, "=").concat(props.data.title),
          passHref: true,
          children: _jsxs("a", {
            css: collectionItemContainer,
            children: [_jsx("img", {
              src: CollectionConfig[index].patternImage,
              css: collectionPatternImage
            }), _jsxs("div", {
              css: collectionItemContentContainer,
              children: [_jsxs("div", {
                children: [_jsxs("div", {
                  css: collectionItemPrimarySIAContainer,
                  children: [_jsxs("picture", {
                    children: [_jsx("source", {
                      srcSet: (_siaDisplayImages$ = siaDisplayImages[0]) === null || _siaDisplayImages$ === void 0 ? void 0 : _siaDisplayImages$.webP,
                      type: "image/webp"
                    }), _jsx("img", {
                      src: (_siaDisplayImages$2 = siaDisplayImages[0]) === null || _siaDisplayImages$2 === void 0 ? void 0 : _siaDisplayImages$2.url,
                      className: "w-full h-full object-cover rounded-md"
                    })]
                  }), isLoggedIn ? _jsxs(Typography, {
                    type: "Body1",
                    color: "white",
                    custom_style: collectionItemSIAPrice,
                    semi_bold: true,
                    children: ["$", amountFormatter(((_collection$siaDetail = collection.siaDetailsLite[0]) === null || _collection$siaDetail === void 0 ? void 0 : _collection$siaDetail.meta.bestB2bPrice[DOLLAR_CURRENCY_ID].sellingPrice) / 100)]
                  }) : null]
                }), _jsx(Typography, {
                  type: "SubHeading1",
                  color: CollectionConfig[index].textColor,
                  className: "pt-4 whitespace-normal",
                  bold: true,
                  children: collection.title
                })]
              }), _jsxs("div", {
                className: 'text-right',
                children: [_jsxs("div", {
                  css: collectionItemSecondarySIAContainer,
                  children: [_jsxs("picture", {
                    children: [_jsx("source", {
                      srcSet: (_siaDisplayImages$3 = siaDisplayImages[1]) === null || _siaDisplayImages$3 === void 0 ? void 0 : _siaDisplayImages$3.webP,
                      type: "image/webp"
                    }), _jsx("img", {
                      src: (_siaDisplayImages$4 = siaDisplayImages[1]) === null || _siaDisplayImages$4 === void 0 ? void 0 : _siaDisplayImages$4.url,
                      className: "w-full h-full object-cover rounded-md"
                    })]
                  }), isLoggedIn ? _jsxs(Typography, {
                    type: "Body1",
                    color: "white",
                    custom_style: collectionItemSIAPrice,
                    semi_bold: true,
                    children: ["$", amountFormatter(((_collection$siaDetail2 = collection.siaDetailsLite[1]) === null || _collection$siaDetail2 === void 0 ? void 0 : _collection$siaDetail2.meta.bestB2bPrice[DOLLAR_CURRENCY_ID].sellingPrice) / 100)]
                  }) : null]
                }), _jsxs("div", {
                  css: collectionItemSecondarySIAContainer,
                  className: "mt-2",
                  children: [_jsxs("picture", {
                    children: [_jsx("source", {
                      srcSet: (_siaDisplayImages$5 = siaDisplayImages[2]) === null || _siaDisplayImages$5 === void 0 ? void 0 : _siaDisplayImages$5.webP,
                      type: "image/webp"
                    }), _jsx("img", {
                      src: (_siaDisplayImages$6 = siaDisplayImages[2]) === null || _siaDisplayImages$6 === void 0 ? void 0 : _siaDisplayImages$6.url,
                      className: "w-full h-full object-cover rounded-md"
                    })]
                  }), isLoggedIn ? _jsxs(Typography, {
                    type: "Body1",
                    color: "white",
                    custom_style: collectionItemSIAPrice,
                    semi_bold: true,
                    children: ["$", amountFormatter(((_collection$siaDetail3 = collection.siaDetailsLite[2]) === null || _collection$siaDetail3 === void 0 ? void 0 : _collection$siaDetail3.meta.bestB2bPrice[DOLLAR_CURRENCY_ID].sellingPrice) / 100)]
                  }) : null]
                })]
              })]
            })]
          })
        }, collection.usId);
      })
    })]
  });
}
export default CollectionGroup;